<!-- 申报设置 -->
<template>
    <div>
        <!-- 同用組件 -->
        <el-card class="box-card">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item>申报设置</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="hr-20"></div>
            <div class="fl">
                <!-- 搜索組件 -->
                <searchtool Otitle="搜索"/>
            </div>
            <div class="fr">
                <el-button type="success" plain size="mini" @click="requestOpenUp">申请开放</el-button>
                <el-button type="warning" plain size="mini">关闭申请</el-button>
            </div>

            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                      @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>

                <el-table-column fixedn width="320px" prop="title" label="标题" show-overflow-tooltip align="center"/>

                <el-table-column label="开始时间" align="center" width="264px">
                    <template slot-scope="scope">{{ scope.row.createTime }}</template>
                </el-table-column>

                <el-table-column label="截止时间" align="center" width="264px">
                    <template slot-scope="scope">{{ scope.row.expireTime }}</template>
                </el-table-column>

                <el-table-column prop="status" label="状态" align="center"/>

                <el-table-column prop="numbers" label="申报人数" align="center"/>

                <el-table-column label="操作" align="center" width="240px" fixed="right">
                    <template slot-scope="scope">
                        <el-button plain size="mini" type="info" @click="requestSee(scope.row)">查看</el-button>
                        <el-button plain type="primary" size="mini" @click="requestEdit(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" v-model="pages"
                           @current-change="requestPage" id="pagination"/>
        </el-card>

        <el-dialog v-model="createDialog" :visible.sync="createDialog" width="55%">
            <el-card v-loading="createLoading">
                <el-header>
                    <h3>申报信息</h3>
                </el-header>
                <el-row>
                    <el-col>
                        <span class="span">政策标题：{{ tableData.title }}</span>
                    </el-col>
                    <el-col>
                        <span class="span">开始时间：{{ tableData.createTime }}</span>
                    </el-col>
                    <el-col>
                        <span class="span">截止时间：{{ tableData.expireTime }}</span>
                    </el-col>
                    <el-col>
                        <span class="span">当前状态：{{ tableData.status }}</span>
                    </el-col>
                </el-row>
                <div class="dialog-btn">
                    <el-row type="flex" justify="center">
                        <el-col :span="4">
                            <el-button type="primary" plain @click="createDialog=!createDialog">确定</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
        </el-dialog>

        <el-dialog :visible.sync="editDialog" width="55%">
            <el-card v-loading="createLoading">
                <el-header>
                    <h3>申报信息</h3>
                </el-header>
                <el-row>
                    <el-col class="data-picker">
                        <span>开始时间: </span>
                        <el-date-picker v-model="newData.createTime" type="datetime" placeholder="选择日期时间"/>
                    </el-col>
                    <el-col class="data-picker">
                        <span>截止时间: </span>
                        <el-date-picker v-model="newData.expireTime" type="datetime" placeholder="选择日期时间"/>
                    </el-col>
                </el-row>
                <div class="dialog-btn">
                    <el-row type="flex" justify="center">
                        <el-col :span="4">
                            <el-button type="success" plain @click="requestModify">确定</el-button>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" plain @click="editDialog=!editDialog">取消</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
    //import headtitle from "@/components/ControlPanel/headtitle";
    import searchtool from "@/components/ControlPanel/searchtool";

    export default {
        components: {
            //headtitle,
            searchtool
        },
        data() {
            return {
                createLoading: false,
                createDialog: false,
                editDialog: false,
                page: 1,
                pages: 0,
                pageSize: 10,
                total: 1,
                tableData: [],
                multipleSelection: [],
                value: '',
                newData: {
                    id: '',
                    expireTime: '',
                },
                openUpData: {
                    id: '',
                    createTime: '',
                },
            };
        },
        created() {
            this.getData();
        },
        methods: {
          request() {
            let newPage = this.$router.resolve({
              name: 'EntryStoma',
            })
            window.open(newPage.href, '_blank')
          },
            getData() {
                this.$axios.get('/policy/page', {
                    params: {
                        current: 1,
                        size: this.pageSize
                    }
                }).then(response => {
                    this.tableData = response.data.data.records;
                    this.total = response.data.data.total;
                })
            },

            /**
             * 分页
             * @param value number
             */
            requestPage(value) {
                this.$axios.get('/policy/page', {
                    params: {
                        current: value,
                        size: this.pageSize
                    }
                }).then(response => {
                    this.tableData = response.data.data.records;
                })
            },

            // 申请开放
            requestOpenUp() {
                this.$axios.put('/policy', this.openUpData).then(response => {
                })
            },

            /**
             * 查看
             * @param row object
             */
            requestSee(row) {
                this.createDialog = true;
                this.$axios.get('/policy/content?id=' + row.id).then(response => {
                    this.tableData.title = response.data.data.title;
                    this.tableData.createTime = response.data.data.createTime;
                    this.tableData.expireTime = response.data.data.expireTime;
                    this.tableData.status = response.data.data.status
                    if (response.data.data.status === 0) {
                        this.tableData.status = '已关闭';
                    } else {
                        this.tableData.status = '已开启'
                    }
                }).catch(error => {
                    console.error(error)
                })
            },

            // 修改时间
            requestModify() {
                if (this.tableData.createTime <= 0) {
                    this.$root.warn("请输入开始时间")
                    return
                }
                if (this.tableData.expireTime <= 0) {
                    this.$root.warn("请输入截止时间")
                    return;
                }
                this.$axios.put('/policy', this.newData).then(response => {
                    if (response.data.status === 200) {
                        this.$root.success("修改成功")
                    } else {
                        this.$root.warn("修改失败")
                    }
                    this.editDialog = false;
                }).catch(error => {
                    console.error(error)
                })
            },

            /**
             *  编辑
             * @param row object
             */
            requestEdit(row) {
                this.editDialog = true;
                this.$axios.get('/policy/details?id=' + row.id).then(response => {
                    this.newData.createTime = response.data.data.createTime;
                    this.newData.expireTime = response.data.data.expireTime;
                    this.newData.id = response.data.data.id;
                }).catch(error => {
                  console.error(error)
                })
            },
            /**
             * 筛选
             * @param rows object
             */
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(value) {
                this.multipleSelection = value;
            }
        }
    };
</script>

<style lang="less" scoped>
    .span {
        background: #f3f3f3;
        display: block;
        height: 40px;
        font-size: 16px;
        text-align: center;
        padding: 0;
        line-height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        margin: 16px;
    }

    #pagination {
        margin: 32px 0 16px 0;
    }

    .dialog-btn {
        margin: 32px;
    }

    .data-picker {
        margin: 24px;
    }

    #pagination {
        margin: 32px 0 16px 0;
    }
</style>