<template>
  <div>
    <!-- 搜索工具 -->
    <div class="searchtool">
      <el-form :model="ruleForm" ref="ruleForm" label-width="0" class="ruleForm" size="mini" inline>
        <el-form-item>
          <el-input v-model="ruleForm.name" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="ruleForm.region" placeholder="请选择状态">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="ruleForm.date1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('ruleForm')">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "searchtool",
  props: {
    Otitle: String
  },
  data() {
    return {
      ruleForm: {
        name: "",
        region: "",
        date1: ""
      }
    };
  },
  methods: {
    searchSubmit() {
    }
  }
};
</script>

<style lang="less" scoped>
.searchtool {
  //background: #1090e7;
  height: 50px;
  text-align: left;
  line-height: 50px;
  padding: 0 0 0 16px;
  color: #fff;
  font-size: 16px;
  .ruleForm {
    float: left;
  }
}
</style>